import { AnySchema, ValidationError } from "yup";

type CombineMap<T extends object> = {
  [P in keyof T]: AnySchema<T[P]>;
};

export type CombineResult<T extends object> = {
  values: {
    [P in keyof T]?: T[P];
  };
  errors: {
    [P in keyof T]?: ValidationError;
  };
};

export const combineValidator =
  <T extends object = Record<string, unknown>>(map: CombineMap<T>) =>
  (values: T): CombineResult<T> => {
    const resultValues = {} as CombineResult<T>["values"];
    const resultErrors = {} as CombineResult<T>["errors"];
    for (const key in map) {
      const schema = map[key];

      try {
        resultValues[key] = schema.validateSync(values[key]) as T[Extract<
          keyof T,
          string
        >];
      } catch (error) {
        resultErrors[key] = error;
      }
    }

    return {
      values: resultValues,
      errors: resultErrors,
    };
  };
